import { io } from "socket.io-client";

export const socket = io(
  process.env.NEXT_PUBLIC_API_BASE_URL!.replace("/api", ""),
  {
    autoConnect: true,
    transports: ["websocket"],

    query: {
      platform: "web",
    },
  },
);
